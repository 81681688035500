import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./router/router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/common.css";

// import WebSocketPlugin from "./utils/WebSocketPlugin";

// Vue.use(WebSocketPlugin, {});

Vue.prototype.$baseMessage = (
  message,
  type = "info",
  customClass = undefined,
  dangerouslyUseHTMLString = false
) => {
  ElementUI.Message({
    message,
    type,
    customClass,
    duration: 3000,
    dangerouslyUseHTMLString,
    showClose: true,
  });
};

Vue.config.productionTip = false;

Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  mode: "history",
});

Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
